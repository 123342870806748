import { memo as Memo } from 'react'

//* HOC's
import { withDataContext, withLanguageContext } from 'context'

//* Component
import { Page, OurPartners } from 'components/common'
import {
	HeroSection,
	LoveWeprofitSection,
	CustomerTypeSection,
	TopCategoriesSection,
	HowItWorksSection,
	LookingHireSection,
	SliderSection,
	DataSecuritySection,
} from 'components/pages'

const Home = Memo(({ pages, global, pageParams, selectedLang, ...props }) => {
	const data = {
		firsSection: {
			text: 'WeProfit is a global marketplace matching and enabling companies to do digital business ',
		},
		secondSection: [
			{
				title: 'Quality',
				text: 'Our vetted suppliers guarantee an efficient matching and world class project delivery.',
			},
			{
				title: 'Speed',
				text: 'Due to our intense screening and preparation your coding can start after days.',
			},
			{
				title: 'Security',
				text: 'Your contracting, payment & data is safe on our platform framework.',
			},
			{
				title: 'Digital',
				text: 'Get best practice and guidance through our intuitive and simple customer journey.',
			},
		],
		thirdSection: [
			{
				title: 'Buyer',
				text: 'Post new software development projects at any time in any industry and find matching supplier companies.',
				img: '/video/Buyer.mp4',
				benefitsItem: [
					'A qualitative and <strong>cost-efficient</strong> way to outsource <em>software development tasks</em> for your <strong>business.</strong>',
					'Intuitive and smooth <strong>user experience </strong>combined with <em>unprecedented global resources.</em>',
					'<strong>Secure, transparent and digital</strong> access to a <em> diverse and filtered database </em> of <strong>suppliers.</strong>',
				],
			},
			{
				title: 'Supplier',
				text: 'Present your portfolio, find new projects, compete on global markets and grow.',
				img: '/video/Supplier.mp4',
				benefitsItem: [
					'Unique exposure on <em>international markets</em> via the <strong>WeProfit marketplace.</strong>',
					'Easy and <em>efficient</em> way to attract new customers on a <strong>new market.</strong>',
					'<strong>Professionalization</strong> through inclusion in <em>global competition.</em>',
				],
			},
			{
				title: 'Connector',
				text: 'Use your network to connect projects with suitable suppliers and earn your commission.',
				img: null,
				benefitsItem: [
					'Participation on <em>successful matches</em> via <strong>commission.</strong>',
					'<strong>Contributing</strong> to a newly conceived <em>international cooperation.</em>',
					'Digital and <em>intuitive access</em> to a professional <strong>global community.</strong>',
				],
			},
		],
		fourthSection: [
			'Enterprise software development',
			'Web design',
			'Mobile app development',
			'Web application',
			'SaaS ',
			'PaaS/loT',
			'AI/ML',
			'Blockchain',
		],
		fifthSection: {
			descripion: 'The WeProfit process will allow you to easily post / apply and process software development projects.',
			items: [
				{
					title: 'Post / Apply',
					text: 'As a buyer the weprofit project scope generator will guide you through the creation of a precise and efficient project scope. As a Supplier you can bid on posted projects and negotiate the most important points to kick off a successful collaboration.',
				},
				{
					title: 'Matching',
					text: 'Match with the most fitting software development Supplier after scoping your project. Match as Supplier with your ideal clients and create suitable matches as Connector.',
				},
				{
					title: 'Processing',
					text: 'Use the intuitive user experience to configure, negotiate and conduct your software development projects. Simple, fast and uncomplicated.',
				},
			],
		},
		sixthSection: [
			'images/home/OurPartners/img1.svg',
			'images/home/OurPartners/img2.svg',
			'images/home/OurPartners/img3.svg',
			'images/home/OurPartners/img4.svg',
			'images/home/OurPartners/img5.svg',
		],
		sevenSection: {
			text: 'Post your software development project as a Buyer or apply for new and interesting projects as a Supplier.',
		},
		eighthSeection: [
			{
				title: 'Mobile App - MVP',
				text: 'Building and development of a blockchain mobile app from wireframes.',
				img: 'images/home/brandYooba.svg',
				quote: '“With the help of WeProfit, we found a company that built our first mobile app and supported us like a technical founder in the development process.”',
				autorName: 'Lukas Weniger',
				autorImg: '/images/home/Lukas.jpeg',
				autorPosition: 'Co-Founder',
			},
			{
				title: 'Web-Software (SaaS)',
				text: 'Development of a multifunctional scraper and a database for the LegalTech product “Lexguard”.',
				quote: '“WeProfit is the platform we always find ourselves coming back to. Adding functionalities to our existing software or creating new tools from scratch, WeProfit got it covered.”',
				img: 'images/home/brandLexemo.svg',
				autorName: 'Pascal DiPrima',
				autorImg: '/images/home/pscal.jpeg',
				autorPosition: 'CEO',
			},
			{
				title: 'Mobile App',
				text: 'Adding key functionalities to our mobile application for our KYC product',
				img: 'images/home/brandQundo.svg',
				quote: '“We needed to add a lot of functionalities to our App in a short period of time. Through WeProfit,  we found a partner and got the project off the ground quickly without cutting corners on quality.”',
				autorName: 'Julian Metzler',
				autorImg: '/images/home/JulianMetzler.jpg',
				autorPosition: 'CTO',
			},
		],
		lastSection: [
			{
				title: '2 factor authentication',
				text: 'Will secure your account from outside',
			},
			{
				title: 'Servers hosted in germany',
				text: 'It is reliable, safe and of high quality',
			},
			{
				title: 'TLS encryption',
				text: 'Stands for hypertext transfer protocol secure',
			},
			{
				title: 'Secure payments',
				text: 'We will secure your payments with  escrow systems',
			},
			{
				title: 'Strong data governance',
				text: 'High quality data management through all phases of the data lifecycle',
			},
			{
				title: 'Compliant with EU-GDPR',
				text: 'Your data is processed according to the highest standards',
			},
		],
	}

	return (
		<Page className='home'>
			<HeroSection data={data.firsSection} />
			<LoveWeprofitSection data={data.secondSection} />
			<CustomerTypeSection data={data.thirdSection} />
			<TopCategoriesSection data={data.fourthSection} />
			<HowItWorksSection data={data.fifthSection} />
			<OurPartners data={data.sixthSection} marginBottom={true} />
			<LookingHireSection data={data.sevenSection.text} />
			<SliderSection data={data.eighthSeection} />
			<DataSecuritySection data={data.lastSection} />
		</Page>
	)
})

export default withLanguageContext(withDataContext(Home, ['pages', 'global']), ['selectedLang'])
